









import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import BatchForm from "@/application-manager/components/BatchForm.vue";
import BatchRequestModel from "@/application-manager/models/BatchRequestModel";
import BatchResponseModel from "@/application-manager/models/BatchResponseModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    BatchForm,
  },
})
export default class BatchView extends mixins(UnsavedChangesMixin) {
  localBatch = new BatchRequestModel();
  isLoading = true;
  isWatchedJurisdiction = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localBatch);
  }

  get batchId(): string {
    return this.$route.params.batchId;
  }

  get batch(): BatchResponseModel {
    return this.$store.state.batchStore.batch;
  }

  @Watch("batch", { deep: true })
  private watchBatch(batch: BatchResponseModel) {
    this.localBatch = BatchRequestModel.ofSource(batch);

    if (!this.isWatchedJurisdiction) {
      this.isWatchedJurisdiction = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.batch.singular")
    );
  }

  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch("loadScenarios"),
      this.$store.dispatch("loadBatch", this.batchId),
    ]);
    this.isLoading = false;
    this.handleWatchingUnsavedChanges();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async handleSave(payload: BatchRequestModel) {
    await this.$store.dispatch("updateBatch", payload);
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.BATCHES,
    });
  }
}
